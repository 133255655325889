






















import { mdiTrashCan } from "@mdi/js"

export default defineComponent({
  emits: ["delete"],
  setup() {
    const dialog = ref(false)

    return { dialog, mdiTrashCan }
  },
})
