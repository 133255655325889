

















































import { mdiPencil, mdiTrashCan } from "@mdi/js"

import type { UserSchema } from "@/api/Auth"
import type { PipelineLongResponse } from "@/api/Pipelines"
import Pipelines from "@/api/Pipelines"
import { usePipelineStatus } from "@/composables/pipelineStatus"
import { useStatus } from "@/composables/status"
import { useUser } from "@/store/user"

import Alert from "./Alert.vue"
import DeleteDialog from "./DeleteDialog.vue"
import PipelineOwner from "./PipelineOwner.vue"
import PythonEditor from "./PythonEditor.vue"
import UserSelect from "./UserSelect.vue"

export default defineComponent({
  components: {
    Alert,
    DeleteDialog,
    PipelineOwner,
    PythonEditor,
    UserSelect,
  },
  emits: ["update:ts", "update:pl"],
  props: {
    loading: {
      required: true,
      type: Number,
    },
    pipeline: {
      type: Object as () => PipelineLongResponse,
    },
    queues: {
      required: true,
      type: Array as () => string[],
    },
  },
  setup(props, { root, emit }) {
    const userSelect = ref("")

    const { statusAlert, resetStatus, setStatus } = useStatus()
    const user = useUser()
    const { detailedStatus, pipelineStatus } = usePipelineStatus()

    const isOwner = computed(() => {
      if (!props.pipeline || !user.user) {
        return false
      } else {
        return user.user.scopes.includes("TENANT_ADMIN") || user.user.id === props.pipeline.userId
      }
    })

    const progress = reactive({
      delete: false,
      enable: false,
      execute: false,
    })

    const icons = {
      mdiPencil,
      mdiTrashCan,
    }

    const enabled = computed(() => {
      return props.pipeline && props.pipeline.enabled && props.pipeline.user
    })

    async function deletePipeline() {
      if (!props.pipeline) return
      resetStatus()
      progress.delete = true
      const response = await Pipelines.delete(user.authConfig, props.pipeline.id)

      if (response.data != null) {
        root.$router.replace("/pipelines")
      } else {
        setStatus("error", response.error)
      }
      progress.delete = false
    }

    async function changeOwner(new_user: UserSchema) {
      if (!props.pipeline) return
      const response = await Pipelines.putOwner(user.authConfig, props.pipeline.id, new_user)

      if (response.data != null) {
        emit("update:pl", response.data)
        setStatus("success", "Owner updated")
      } else {
        setStatus("error", response.error)
      }
    }

    async function executePipeline() {
      if (!props.pipeline) return
      progress.execute = true
      const response = await Pipelines.execute(user.authConfig, props.pipeline.id)

      if (response.data != null) {
        if (response.data.status) {
          setStatus("error", `The pipeline didn't execute successfully, more details in the latest timeserie below`)
        } else {
          setStatus("success", "Pipeline executed successfully")
        }
        emit("update:ts")
      } else {
        setStatus("error", response.error)
      }
      progress.execute = false
    }

    async function disablePipeline() {
      if (!props.pipeline) return
      resetStatus()
      progress.enable = true
      const data = {
        ...props.pipeline,
        enabled: false,
        user: null,
      }

      const response = await Pipelines.put(user.authConfig, data.id, data)

      if (response.data != null) {
        emit("update:pl")
      } else {
        setStatus("error", response.error)
      }
      progress.enable = false
    }

    async function enablePipeline() {
      if (!props.pipeline) return
      resetStatus()
      progress.enable = true
      const data = {
        ...props.pipeline,
        enabled: true,
        user: user.execUser,
      }

      const response = await Pipelines.put(user.authConfig, data.id, data)

      if (response.data != null) {
        emit("update:pl")
      } else {
        setStatus("error", response.error)
      }
      progress.enable = false
    }

    return {
      changeOwner,
      deletePipeline,
      detailedStatus,
      disablePipeline,
      enablePipeline,
      enabled,
      executePipeline,
      icons,
      isOwner,
      pipelineStatus,
      progress,
      resetStatus,
      statusAlert,
      userSelect,
    }
  },
})
