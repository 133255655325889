





















import { computed, defineComponent, onMounted, ref, watch } from "@vue/composition-api"

import type { TimeserieResponse } from "@/api/Pipelines"
import Pipelines from "@/api/Pipelines"
import { useStatus } from "@/composables/status"
import { useUser } from "@/store/user"

import TimeseriesTable from "./TimeseriesTable.vue"

export default defineComponent({
  components: { TimeseriesTable },
  props: {
    pipelineId: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const user = useUser()
    const { statusAlert, setStatus, resetStatus } = useStatus()
    const loading = ref(true)
    const timeseries = ref([] as TimeserieResponse[])
    const page = ref(0)
    const now = ref(Date.now())
    const pageSize = 1000 * 60 * 15 //15 mins as ms
    const endTs = computed(() => now.value - page.value * pageSize)
    const startTs = computed(() => now.value - (page.value + 1) * pageSize)

    function dateFromTs(ts: number) {
      return new Date(ts).toLocaleString()
    }

    async function fetchTimeseries() {
      resetStatus()
      const response = await Pipelines.getTimeseries(user.authConfig, props.pipelineId, startTs.value, endTs.value)
      if (response.data != null) {
        timeseries.value = response.data
      } else {
        setStatus("error", response.error)
      }
      loading.value = false
    }

    function onLater() {
      if (page.value == 0) {
        now.value = Date.now()
        fetchTimeseries()
      } else {
        page.value--
      }
    }

    function refresh() {
      page.value = 0
      now.value = Date.now()
      fetchTimeseries()
    }

    watch(page, fetchTimeseries)
    onMounted(fetchTimeseries)
    return {
      dateFromTs,
      endTs,
      loading,
      onLater,
      page,
      refresh,
      startTs,
      statusAlert,
      timeseries,
    }
  },
})
