






















import type { UserSchema } from "@/api/Auth"

import UserSelect from "./UserSelect.vue"

export default defineComponent({
  components: { UserSelect },
  emits: ["update"],
  setup(_, { emit }) {
    const dialog = ref(false)

    const user = ref(null as UserSchema | null)

    function update() {
      dialog.value = false
      emit("update", user.value)
    }

    return { dialog, update, user }
  },
})
