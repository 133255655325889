


















import type { PipelineLongResponse } from "@/api/Pipelines"
import Pipelines from "@/api/Pipelines"
import Alert from "@/components/Alert.vue"
import PipelineForm from "@/components/PipelineForm.vue"
import { useStatus } from "@/composables/status"
import { useUser } from "@/store/user"

export default defineComponent({
  components: { Alert, PipelineForm },
  setup(_, { root }) {
    const user = useUser()
    const { statusAlert, resetStatus, setStatus } = useStatus()
    const pipelineId = root.$route.params["pipelineId"]
    const pipeline = ref<PipelineLongResponse | null>(null)

    const loading = ref(true)

    async function fetchPipeline() {
      resetStatus()
      const response = await Pipelines.get(user.authConfig, pipelineId)
      if (response.data != null) {
        pipeline.value = response.data
      } else {
        setStatus("error", response.error)
      }
      loading.value = false
    }

    watchEffect(() => {
      if (!user.isConnected) root.$router.replace("/login")
    })
    onMounted(fetchPipeline)

    return { loading, pipeline, resetStatus, statusAlert }
  },
})
