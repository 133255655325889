






















import type { PipelineLongResponse } from "@/api/Pipelines"
import Pipelines from "@/api/Pipelines"
import Workers from "@/api/Workers"
import Alert from "@/components/Alert.vue"
import PipelineDetail from "@/components/PipelineDetail.vue"
import PipelineOutput from "@/components/PipelineOutput.vue"
import { useStatus } from "@/composables/status"
import { useUser } from "@/store/user"

export default defineComponent({
  components: { Alert, PipelineDetail, PipelineOutput },
  setup(_, { root }) {
    const { statusAlert, resetStatus, setStatus } = useStatus()
    const user = useUser()
    const pipelineId = root.$route.params["pipelineId"]
    const pipeline = ref<PipelineLongResponse | null>(null)
    const queues = ref([] as string[])
    const loading = ref(2)

    const outputRef = ref<InstanceType<typeof PipelineOutput> | null>(null)

    async function fetchPipeline() {
      resetStatus()
      const response = await Pipelines.get(user.authConfig, pipelineId)
      if (response.data != null) {
        pipeline.value = response.data
      } else {
        setStatus("error", response.error)
      }
      loading.value -= 1
    }

    async function fetchQueues() {
      const response = await Workers.queues(user.authConfig)
      if (response.data != null) {
        queues.value = response.data
      } else {
        setStatus("error", response.error)
      }
      loading.value -= 1
    }

    function refreshTs() {
      outputRef.value?.refresh()
    }

    watchEffect(() => {
      if (!user.isConnected) root.$router.replace("/login")
    })
    onMounted(() => {
      fetchQueues()
      fetchPipeline()
    })

    return {
      fetchPipeline,
      loading,
      outputRef,
      pipeline,
      queues,
      refreshTs,
      statusAlert,
    }
  },
})
