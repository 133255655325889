



































import { mdiImport, mdiNoteText } from "@mdi/js"
import { defineComponent } from "@vue/composition-api"

import type { TimeserieResponse } from "@/api/Pipelines"

import TextboxDialog from "./TextboxDialog.vue"

export default defineComponent({
  components: { TextboxDialog },
  props: {
    timeseries: {
      required: true,
      type: Array as () => TimeserieResponse[],
    },
  },
  setup() {
    function dateFromTs(ts: string) {
      return new Date(ts + "+00:00").toLocaleString()
    }
    return { dateFromTs, mdiImport, mdiNoteText }
  },
})
