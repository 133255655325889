












import type { StateChanger } from "vue-infinite-loading"

import type { UserResponse } from "@/api/Auth"
import Auth from "@/api/Auth"
import { useUser } from "@/store/user"

export default defineComponent({
  props: ["value", "label"],
  setup() {
    const user = useUser()

    const users = ref([] as UserResponse[])
    const page = ref(0)
    const pageSize = 25

    const items = computed(() =>
      users.value.map((user) => ({
        text: user.email,
        value: user,
      }))
    )

    async function fetchUsers() {
      const response = await Auth.getUsers(user.authConfig, page.value, pageSize, "")

      if (response.data !== null) {
        users.value.push(...response.data.data)
        return response.data.data.length != 0
      }
      return false
    }

    async function infiniteLoader($state: StateChanger) {
      const hasMore = await fetchUsers()
      if (hasMore) {
        page.value += 1
        $state.loaded()
      } else {
        $state.complete()
      }
    }

    return {
      infiniteLoader,
      items,
    }
  },
})
