





















export default defineComponent({
  props: {
    button: String,
    content: String,
    icon: String,
    title: String,
  },
  setup() {
    const dialog = ref(false)

    return { dialog }
  },
})
